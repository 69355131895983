import React from "react";
import { polyfill } from "seamless-scroll-polyfill";
import { Root } from "../views";
import { useRedirectByLang } from "../hooks";
import { DMMPopupContextProvider } from "../hooks/useDMMPopupState";

if (typeof window !== "undefined") polyfill();

const IndexPage = () => {
  useRedirectByLang();

  return (
    <DMMPopupContextProvider>
      <Root />
    </DMMPopupContextProvider>
  );
};

export default IndexPage;
